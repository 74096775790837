import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom"
import { LockOutlined } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuBar from "../../components/Menu";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { useStyles } from "../../Layout/styles";
import authService from "src/services/authService";

interface LeftBarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const LeftBar = (props: LeftBarProps) => {
  const { open, handleDrawerClose, handleDrawerOpen } = props;
  const classes = useStyles();
  return (
    <SwipeableDrawer
      variant="permanent"
      classes={{
        paper: classNames(
          open ? classes.drawerPaper : classes.drawerPaperClose
        ),
      }}
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
    >
      <div className={classes.toolbar}>
        <Typography color="inherit" noWrap>
          Cuponero
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <MenuBar />
      </List>
      <Divider />
      <List>
        <Link to='/'>
          <ListItem button onClick={() => authService.userLogout()}>
            <ListItemIcon>
              <LockOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
    </SwipeableDrawer>
  );
};

export default LeftBar;
