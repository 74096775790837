import { setCoupons } from "src/pages/Coupon/couponSlice";
import { store } from "src/store";
import { getApi } from "../../api/ApiProvider";

class couponService {
  async list(companyId: number): Promise<any> {
    try {
      const response = await getApi().coupon().list(companyId);

      if (!response) throw new Error("Error access user");

      if (response) {
        store.dispatch(setCoupons(response));
      }
    } catch (error) {
      throw error;
    }
  }

  async filter(search: string, companyId: number): Promise<any> {
    try {
      const response = await getApi().coupon().filter(search, companyId);

      if (!response) throw new Error("Error filter coupon");

      if (response) {
        store.dispatch(setCoupons(response));
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new couponService();
