import { IUser } from "../utils/userHandler";
import CouponAPI from "./coupon-api";
import ClientApi from "./client-api";
import CouponClientApi from "./couponClient-api";

export class BaseCaller {
  private path: string;
  private token: string | null;
  private handler: (response: any) => boolean;

  constructor(path: string, token: string | null) {
    this.path = path;
    this.token = token;

    this.handleResponse = this.handleResponse.bind(this);
  }

  public setPath(path: string) {
    if (this.path === path) {
      return;
    }
    this.path = path;
    this.token = null;
  }

  public setToken(token: string | null) {
    this.token = token;
  }

  public get(suffix: string): Promise<any> {
    return fetch(`${this.path}/${suffix}`, {
      method: "GET",
      headers: this.buildHeaders(),
    }).then(this.handleResponse.bind(this));
  }

  public post(suffix: string, body: any): Promise<any> {
    return fetch(`${this.path}/${suffix}`, {
      method: "POST",
      headers: this.buildHeaders(),
      body: JSON.stringify(body),
    }).then(this.handleResponse);
  }

  public put(suffix: string, body: any): Promise<any> {
    return fetch(`${this.path}/${suffix}`, {
      method: "PUT",
      headers: this.buildHeaders(),
      body: JSON.stringify(body),
    }).then(this.handleResponse);
  }

  public postForm(suffix: string, body: { [key: string]: any }) {
    const data = new URLSearchParams();
    for (const key of Object.keys(body)) {
      data.append(key, body[key]);
    }

    return fetch(`${this.path}/${suffix}`, {
      method: "post",
      body: data,
    }).then(this.handleResponse);
  }

  public postFormFile(suffix: string, body: any) {
    const data = new FormData();
    data.append("file", body.image);
    data.append("user", "hubost");

    // Weird stuff, you don't need to send the content-type to make it work
    return fetch(`${this.path}/${suffix}`, {
      method: "post",
      body: data,
      headers: {
        Accept: "application/json",
        Authorization: this.token || "",
      },
    }).then(this.handleResponse);
  }

  public addHandler(handler: (err: any) => boolean) {
    this.handler = handler;
  }

  /**
   * Builds the common headers to use the API
   */
  private buildHeaders() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    };

    if (this.token) {
      headers.Authorization = this.token;
    }
    return headers;
  }

  /**
   * Handle the server response.
   *
   * This check for the status code, and always try to return an JSON object.
   *
   * @param {Response} response
   */
  private handleResponse(response: any) {
    if (response.headers.get("Content-Type") === "application/octet-stream") {
      return response.blob();
    }

    if (response.status >= 200 && response.status <= 300) {
      return response.json();
    }

    if (this.handler && this.handler(response)) {
      console.warn("without handler, returning ok when the result is not ok");
      return;
    }

    if (response.status === 401) {
      throw new Error(response.status);
    }

    return response.json().then((data: any) => {
      const error: any = new Error(response.statusText || response.status);
      error.status = response.status;
      error.statusText = response.statusText;
      error.response = data;
      return Promise.reject(error);
    });
  }
}

class AuthController {
  constructor(public caller: BaseCaller) {
    this.caller = caller;
  }

  public login(user: string, pass: string): Promise<IUser> {
    return this.caller.postForm("admin/login", {
      username: user,
      password: pass,
    });
  }
}

export default class API {
  private readonly apiUrl: string;
  private readonly caller: BaseCaller;

  constructor(url: string) {
    this.apiUrl = url;
    this.caller = new BaseCaller(this.apiUrl, null);
  }

  public notifyLogin(token: string) {
    this.caller.setToken(token);
  }

  public notifyNewUrl(path: string): any {
    this.caller.setPath(path);
  }

  public notifyLogout() {
    this.caller.setToken(null);
  }

  public addErrorHandler(handler: (err: any) => boolean) {
    this.caller.addHandler(handler);
  }

  public auth() {
    return new AuthController(this.caller);
  }
  public coupon() {
    return new CouponAPI(this.caller);
  }
  public couponClient() {
    return new CouponClientApi(this.caller);
  }
  public client() {
    return new ClientApi(this.caller);
  }
}
