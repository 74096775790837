import React from "react";
import AsyncTable, { ColumnDefinition } from "../AsyncTable";

interface ClientListProps {
  data: any;
}

const ClientList = (props: ClientListProps) => {
  const columns: ColumnDefinition[] = [
    {
      path: "document",
      numeric: true,
      disablePadding: false,
      label: "Cédula",
    },
    {
      path: "user.name",
      numeric: false,
      disablePadding: false,
      label: "Nombre",
    },
    {
      path: "user.email",
      numeric: false,
      disablePadding: false,
      label: "Correo",
    },
    {
      path: "phone",
      numeric: true,
      disablePadding: false,
      label: "Teléfono",
    },
  ];

  return (
    <div style={{ padding: 30 }}>
      <AsyncTable
        title={"Lista de clientes que aceptaron cupones"}
        wrapInPaper={false}
        columnDefinition={columns}
        data={props.data}
      />
    </div>
  );
};

export default ClientList;
