import { FormControl, Input, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { useState } from "react";

export interface SearchBoxProps {
  doSearch: (value: string) => void;
  placeHolder: string;
}

const SearchBox = (props: SearchBoxProps) => {
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { doSearch, placeHolder } = props;

  const keyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doSearch(searchValue);
      setSearching(true);
    }
  };

  const getEndAdornment = () => {
    if (searching) {
      return (
        <InputAdornment
          position="end"
          onClick={() => {
            setSearching(false);
            setSearchValue("");
            doSearch("");
          }}
        >
          <HighlightOffIcon />
        </InputAdornment>
      );
    }
    return <></>;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      <FormControl style={{ width: 400 }}>
        <Input
          id="input-with-icon-adornment"
          placeholder={placeHolder}
          value={searchValue}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={getEndAdornment()}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyUp={keyUp}
          disabled={searching}
          onClick={() => {
            setSearching(false);
          }}
        />
      </FormControl>
    </div>
  );
};

export default SearchBox;
