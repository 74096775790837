import makeStyles from "@material-ui/core/styles/makeStyles";
import { lighten } from "@material-ui/core/styles/colorManipulator";

/**
 * Estilos del header
 */
export const toolbarStyles = makeStyles((theme) => ({
  root: {
    // paddingRight: theme.spacing(1),
    paddingRight: 15,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

/**
 * Estos son los estilos principales
 */
export const styles = makeStyles((theme) => ({
  wrapperDiv: {
    width: "100%",
  },
  root: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    // margin: theme.spacing(1),
    margin: 10,
  },
  table: {
    width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    // margin: theme.spacing(1),
    margin: 10,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));
