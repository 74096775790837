import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { makeSelectCoupon } from "./selectors";
import { createSelector } from "@reduxjs/toolkit";
import CouponList from "../../components/CouponList";

// material
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import couponService from "src/services/couponService";
import { Coupon } from "src/api/model";
import SearchBox from "src/components/SearchBox";
import userHandler from "src/utils/userHandler";

interface CouponPageProps {}

const stateSelector = createSelector(makeSelectCoupon, (coupons) => ({
  coupons,
}));

const CouponPage: FC<CouponPageProps> = (props: CouponPageProps) => {
  const { coupons } = useAppSelector(stateSelector);
  const [couponsData, setCouponsData] = useState<Coupon[] | []>();
  const [search, setSearch] = useState("");
  const companyId = userHandler.getUser()?.companyId || 0;

  const fetch = async () => {
    await couponService.list(companyId).catch((error) => {
      console.log("Error list coupon", error);
    });
  };

  const filter = async (searchValue: any) => {
    const value = searchValue == null ? search : searchValue;
    await couponService.filter(value, companyId).catch((err) => {
      console.log("Error filter coupon", err);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setCouponsData([...coupons]);
  }, [coupons]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Paper style={{ padding: 10 }}>
          <SearchBox
            placeHolder="Ingrese Código y presione ENTER"
            doSearch={(search) => {
              setSearch(search);
              filter(search);
            }}
          />
          <CouponList data={couponsData} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CouponPage;
