import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
  loginButton: {
    margin: 25,
  },
  message: {
    padding: 25,
    width: 350,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
