import React, { useEffect, useState } from "react";
import MainRouter from "./pages/MainRouter";
import { useAppSelector } from "../src/hooks";
import { makeSelectAuth } from "../src/pages/Login/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { buildApi, getApi } from "./api/ApiProvider";
import userHandler from "./utils/userHandler";
import { store } from "./store";
import { Configurations } from "./config";
import { setUser } from "./pages/Login/authSlice";

const stateSelector = createSelector(makeSelectAuth, (user) => ({
  user,
}));

const App = () => {
  const isLogged = userHandler.isLogged();
  const { user } = useAppSelector(stateSelector);
  const [logged, setLogged] = useState(isLogged);

  // console.log("STORE INIT", store.getState());
  buildApi(Configurations.prod.api);
  getApi().notifyLogin(userHandler.getToken());

  const handleLogin = () => {
    const operatorPermission = user?.permissions.find((e: any) => e === 'OPERATOR');

    if ((user !== null || isLogged) && operatorPermission) {
      setLogged(true);
      const userLocal = userHandler.getUser();
      user !== null
        ? userHandler.notifyLogin(user)
        : store.dispatch(setUser(userLocal));
      const userToken =
        user !== null ? user.token : userLocal ? userLocal.token : "";
      getApi().notifyLogin(userToken);
    } else {
      setLogged(false);
    }
  };

  useEffect(() => {
    handleLogin();
  }, [user]);

  return <MainRouter isLogged={logged} />;
};

export default App;
