import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { makeSelectCouponClient } from "./selectors";
import { createSelector } from "@reduxjs/toolkit";
import CouponClientList from "../../components/ChangeCouponList";

// material
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import couponClientService from "src/services/couponClientService";
import { CouponClient } from "src/api/model";
import SearchBox from "src/components/SearchBox";
import userHandler from "src/utils/userHandler";

interface ExchangeProps {}

const stateSelector = createSelector(
  makeSelectCouponClient,
  (couponClients) => ({
    couponClients,
  })
);

const Exchange: FC<ExchangeProps> = (props: ExchangeProps) => {
  const { couponClients } = useAppSelector(stateSelector);
  const [coupons, setCoupons] = useState<CouponClient[] | []>();
  const [search, setSearch] = useState("");
  const companyId = userHandler.getUser()?.companyId || 0;

  const showModal = () => {
    console.log("Modal");
  };

  const fetch = async () => {
    await couponClientService.list(companyId);
  };

  const filter = async (searchValue: any) => {
    const value = searchValue == null ? search : searchValue;
    await couponClientService.filter(value, companyId);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setCoupons([...couponClients]);
  }, [couponClients]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Paper style={{ padding: 10 }}>
          <SearchBox
            placeHolder="Ingrese Ci y presione ENTER"
            doSearch={(search) => {
              setSearch(search);
              filter(search);
            }}
          />
          <CouponClientList data={coupons} showChildren={showModal} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Exchange;
