import React from "react";
import {
  CircularProgress,
  Fade,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { useAppSelector } from "../../hooks";
import { makeSelectNotification } from "../../notifications/selectors";
import { createSelector } from "@reduxjs/toolkit";
import { INotificationState } from "../../notifications/types";
import { styles } from "./styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: ErrorIcon,
  error: ErrorIcon,
  info: InfoIcon,
  loading: CircularProgress,
};

interface SnackbarHandlerProps {
  // actions: NotificationActions;
  // notification?: NotificationState;
}

const stateSelector = createSelector(makeSelectNotification, (information) => ({
  information,
}));

const SnackbarHandler = (props: SnackbarHandlerProps) => {
  const { information } = useAppSelector(stateSelector);
  const state: INotificationState = information || { visible: false };

  let title = (state.data && state.data.title) || "";
  const message = (state.data && state.data.message) || "";
  const type = (state.data && state.data.type) || "success";

  const classes = styles() || ({} as any);
  if (title && message) {
    title += ": ";
  }

  const LeftIcon = variantIcon[type];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className={classes.container}
      open={state.visible}
      TransitionComponent={Fade}
    >
      <SnackbarContent
        className={classes[type]}
        message={
          <span
            className={classes[type]}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LeftIcon className={classes.icon} /> &nbsp;
            <div>
              <b>{title}</b>
              {message}
            </div>
          </span>
        }
      />
    </Snackbar>
  );
};

export default SnackbarHandler;
