import { BaseCaller } from "./api";

export default class CouponClientAPI {
  private caller: BaseCaller;

  constructor(caller: BaseCaller) {
    this.caller = caller;
  }

  public list(id: number): Promise<any> {
    return this.caller.get(`coupon/client/byCompany/${id}`);
  }
  public markAsUsed(id: number): Promise<any> {
    return this.caller.post(`coupon/client/markedCoupon/${id}`, {});
  }

  public filter(search: string, companyId: number) {
    const squery =
      search.trim().toUpperCase().length > 0
        ? `?search=${search.trim().toUpperCase()}`
        : "";
    return this.caller.get(`coupon/client/filter/${companyId}${squery}`);
  }
}
