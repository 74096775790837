export enum NotificationType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export interface INotificationData {
  type: NotificationType;
  title: string;
  message?: string;
}

export interface INotificationState {
  data?: INotificationData;
  visible: boolean;
}

export interface INotification {
  information: INotificationState;
}
