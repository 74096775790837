import { createSlice } from "@reduxjs/toolkit";
import { ICouponState } from "./types";

const initialState: ICouponState = {
  coupons: [],
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCoupons(state, action) {
      state.coupons = action.payload;
    },
  },
});

export const { setCoupons } = couponSlice.actions;
export default couponSlice.reducer;
