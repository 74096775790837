import makeStyles from "@material-ui/core/styles/makeStyles";
import { orange } from "@material-ui/core/colors";

export const styles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(0),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    maxHeight: 50,
    opacity: 0.9,
    color: "white",
    marginRight: theme.spacing(0),
  },
  close: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(0),
  },
  success: {
    backgroundColor: theme.palette.primary.light,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: orange[600],
  },
  warning: {
    backgroundColor: theme.palette.error.light,
  },
  loading: {
    backgroundColor: theme.palette.primary.light,
  },
  loadingIcon: {},
}));
