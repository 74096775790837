import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { makeSelectClient } from "./selectors";
import { createSelector } from "@reduxjs/toolkit";
import { Client } from "src/api/model";
import ClientList from "src/components/ClientList";

// material
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clientService from "src/services/clientService";
import SearchBox from "src/components/SearchBox";
import userHandler from "src/utils/userHandler";

interface ClientPageProps {}

const stateSelector = createSelector(makeSelectClient, (clients) => ({
  clients,
}));

const ClientPage: FC<ClientPageProps> = (props: ClientPageProps) => {
  const { clients } = useAppSelector(stateSelector);
  const [clientsData, setClientsData] = useState<Client[]>();
  const [search, setSearch] = useState("");
  const companyId = userHandler.getUser()?.companyId || 0;

  const fetch = async () => {
    await clientService.list(companyId).catch((error) => {
      console.log("Error list client", error);
    });
  };

  const filter = async (searchValue: any) => {
    const value = searchValue == null ? search : searchValue;
    await clientService.filter(value, companyId).catch((err) => {
      console.log("Error filter client", err);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setClientsData([...clients]);
  }, [clients]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={12}>
        <Paper style={{ padding: 10 }}>
          <SearchBox
            placeHolder="Ingrese CI y presione ENTER"
            doSearch={(search) => {
              setSearch(search);
              filter(search);
            }}
          />
          <ClientList data={clientsData} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ClientPage;
