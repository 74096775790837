import userHandler from "../../utils/userHandler";
import { getApi } from "../../api/ApiProvider";
import { setUser } from "src/pages/Login/authSlice";
import { store } from "src/store";
import { addError, hide } from "src/notifications/notificationSlice";

class authService {
  async userLogin(name: string, password: string) {
    try {
      const response = await getApi().auth().login(name, password);

      if (!response) throw new Error("Error access user");

      if (response) {
        const operatorPermission = response.permissions.find((e: any) => e === 'OPERATOR');
        if (operatorPermission) {
          store.dispatch(setUser(response));
        } else {
          store.dispatch(
            addError({
              title: "Login",
              message: "Inconvenientes con el ingreso, verifique sus datos",
            })
          );
          setTimeout(() => {
            store.dispatch(hide());
          }, 4000);
        }
      }
    } catch (error) {
      store.dispatch(
        addError({
          title: "Login",
          message: "Inconvenientes con el ingreso, verifique sus datos",
        })
      );
      setTimeout(() => {
        store.dispatch(hide());
      }, 4000);
      throw error;
    }
  }

  userLogout() {
    try {
      userHandler.logout();
      getApi().notifyLogout();
      store.dispatch(setUser(null));
    } catch (error) {
      throw error;
    }
  }
}

export default new authService();
