import React, { ReactElement } from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChangeIcon from "@material-ui/icons/Cached";
import CouponIcon from "@material-ui/icons/CreditCard";
import ClientIcon from "@material-ui/icons/People";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

const menu = (): ReactElement => (
  <>
    <Link to="/">
      <Tooltip title="Canje" placement="bottom">
        <ListItem button>
          <ListItemIcon>
            <ChangeIcon />
          </ListItemIcon>
          <ListItemText style={{ color: "black" }} primary="Canje de cupones" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/cupones">
      <Tooltip title="Cupones" placement="bottom">
        <ListItem button>
          <ListItemIcon>
            <CouponIcon />
          </ListItemIcon>
          <ListItemText style={{ color: "black" }} primary="Cupones" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link to="/clientes">
      <Tooltip title="Clientes" placement="bottom">
        <ListItem button>
          <ListItemIcon>
            <ClientIcon />
          </ListItemIcon>
          <ListItemText style={{ color: "black" }} primary="Clientes" />
        </ListItem>
      </Tooltip>
    </Link>

    <Divider />
  </>
);

export default menu;
