import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    backgroundImage:
      "radial-gradient(rgba(149, 190, 218) 1px, transparent 1px), radial-gradient(rgba(149, 190, 218) 1px, transparent 1px)",
    backgroundPosition: "0 0, 25px 25px",
    backgroundSize: "60px 60px",
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
  },
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 300,
  },
  buttonContainer: {
    padding: 10,
    maxWidth: 400,
    margin: "auto",
  },
  loginButton: {
    marginTop: 40,
    marginBottom: 30,
  },
  message: {
    marginTop: 80,
  },
  logo: {
    borderRadius: "50%",
    maxWidth: "120px",
    marginTop: 25,
    marginBottom: 15,
  },
  loginContainer: {
    width: 500,
    borderRadius: 10,
  },
}));
