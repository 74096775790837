import * as React from "react";
import AsyncTable, { ColumnDefinition } from "../AsyncTable";
import { format } from "date-fns";

// material
import Avatar from "@material-ui/core/Avatar";

interface CouponListProps {
  data: any;
}

const CouponList = (props: CouponListProps) => {
  const columns: ColumnDefinition[] = [
    {
      path: "image",
      numeric: false,
      disablePadding: false,
      label: "Imagen",
      draw: (cd: ColumnDefinition, value: any) => {
        return (
          <Avatar
            alt={value.name}
            src={value.image}
            style={{ marginLeft: 30 }}
          />
        );
      },
    },
    { path: "code", numeric: false, disablePadding: false, label: "Código" },
    { path: "name", numeric: false, disablePadding: false, label: "Nombre" },
    {
      path: "description",
      numeric: false,
      disablePadding: false,
      label: "Descripción",
    },
    {
      path: "endDate",
      numeric: false,
      disablePadding: false,
      label: "Valido hasta",
      draw: (cd, v) => v.endDate && format(v.endDate, "dd/MM/yyyy HH:mm"),
    },
  ];

  return (
    <AsyncTable
      title={"Lista de todos los cupones creados"}
      wrapInPaper={false}
      columnDefinition={columns}
      data={props.data}
    />
  );
};

export default CouponList;
