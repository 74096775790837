import { setCouponClients } from "src/pages/Exchange/exchangeSlice";
import { store } from "src/store";
import { getApi } from "../../api/ApiProvider";

class couponClientService {
  async list(companyId: number): Promise<any> {
    try {
      const response = await getApi().couponClient().list(companyId);

      if (!response) throw new Error("Error access user");

      if (response) {
        store.dispatch(setCouponClients(response));
      }
    } catch (error) {
      throw error;
    }
  }
  async markCoupon(id: number, companyId: number): Promise<any> {
    try {
      const response = await getApi().couponClient().markAsUsed(id);

      if (!response) throw new Error("Error mark coupon");

      if (response) {
        this.list(companyId);
      }
    } catch (error) {
      throw error;
    }
  }

  async filter(search: string, companyId: number): Promise<any> {
    try {
      const response = await getApi().couponClient().filter(search, companyId);

      if (!response) throw new Error("Error filter coupon client");

      if (response) {
        store.dispatch(setCouponClients(response));
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new couponClientService();
