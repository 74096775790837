import { setClients } from "src/pages/Client/clientSlice";
import { store } from "src/store";
import { getApi } from "../../api/ApiProvider";

class clientService {
  async list(companyId: number): Promise<any> {
    try {
      const response = await getApi().client().list(companyId);

      if (!response) throw new Error("Error access user");

      if (response) {
        store.dispatch(setClients(response));
      }
    } catch (error) {
      throw error;
    }
  }

  async filter(search: string, companyId: number): Promise<any> {
    try {
      const response = await getApi().client().filter(search, companyId);

      if (!response) throw new Error("Error filter client");

      if (response) {
        store.dispatch(setClients(response));
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new clientService();
