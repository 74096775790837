export const Configurations = {
  dev: {
    api: "http://localhost:8080/api",
    maps: {
      key: "AIzaSyAcZVbIDdV7dM8nAiIPsCXnjUY51gXZPJI",
      startingLocation: {
        lat: -17.775608,
        lng: -63.1812027,
        zoom: 13,
      },
    },
    firebase: {
      apiKey: "AIzaSyBwPHczNSCqBsDJJpjhGGvXgjRFNjbioqE",
      authDomain: "cupon-dev.firebaseapp.com",
      databaseURL: "http://localhost:5001/cupon-dev/us-central1/api",
      projectId: "cupon-dev",
      storageBucket: "cupon-dev.appspot.com",
      messagingSenderId: "394637893343",
    },
  },
  prod: {
    api: "https://api.cupon.com.py/api",
    maps: {
      key: "AIzaSyAcZVbIDdV7dM8nAiIPsCXnjUY51gXZPJI",
      startingLocation: {
        lat: -17.775608,
        lng: -63.1812027,
        zoom: 13,
      },
    },
    firebase: {
      apiKey: "AIzaSyBwPHczNSCqBsDJJpjhGGvXgjRFNjbioqE",
      authDomain: "cupon-dev.firebaseapp.com",
      databaseURL: "https://cupon-dev.firebaseio.com",
      projectId: "cupon-dev",
      storageBucket: "cupon-dev.appspot.com",
      messagingSenderId: "394637893343",
    },
  },
};

export type ConfigData = typeof Configurations.dev;

export const active: string = 'prod';

export function getConfig(configName: string): ConfigData {
  if (Configurations[configName]) {
    return Configurations[configName];
  } else {
    const key = Object.keys(Configurations).find((configKey) => {
      if (Configurations[configKey].name === configName) {
        return true;
      }
      return false;
    });
    if (key) {
      console.log("new config", key);
      return Configurations[key];
    }
  }
  throw new Error(`Configuration with name ${configName} not found`);
}
