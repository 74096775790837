import { createSlice } from "@reduxjs/toolkit";
import { ICouponClientState } from "./types";

const initialState: ICouponClientState = {
  couponClients: [],
};

const exchangeSlice = createSlice({
  name: "couponClient",
  initialState,
  reducers: {
    setCouponClients(state, action) {
      state.couponClients = action.payload;
    },
  },
});

export const { setCouponClients } = exchangeSlice.actions;
export default exchangeSlice.reducer;
