import API from "./api";

let api: API;

export const buildApi = (url: string) => {
  api = new API(url);
  return api;
};

export const getApi = () => {
  return api;
};
