import React, { ReactElement, useEffect, useState, useRef } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter, Link } from "react-router-dom";
import classNames from "classnames";
import userHandler from "../utils/userHandler";
import { useStyles } from "./styles";
import authService from "src/services/authService";
import LeftBar from "src/components/LeftBar";

// pages
import Exchange from "../pages/Exchange";
import CouponPage from "src/pages/Coupon";
import ClientPage from "src/pages/Client";

// material
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Menu } from "@material-ui/core";
import { createSelector } from "@reduxjs/toolkit";
import { makeSelectAuth } from "src/pages/Login/selectors";
import { useAppSelector } from "src/hooks";

const PageError = (): ReactElement => (
  <div>
    <h3>
      Ocurrio un error!, estamos trabajando en resolverlo!.
      <Link to="/">Volver al Inicio</Link>
    </h3>
  </div>
);
const Page404 = (): ReactElement => (
  <div>
    <h3>
      La página que accediste no existe!.
      <Link to="/">volver al Inicio</Link>
    </h3>
  </div>
);

interface LayoutProps { }

const stateSelector = createSelector(makeSelectAuth, (user) => ({
  user,
}));

const Layout = (props: LayoutProps) => {
  const { user } = useAppSelector(stateSelector);
  const [open, setOpen] = useState(false);
  const [profileOptions, setProfileOptions] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileOpen = () => {
    setProfileOptions((prevOpen) => !prevOpen);
  };

  const handleProfileClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setProfileOptions(false);
  };

  const handleLogout = () => {
    setProfileOptions(false);
    authService.userLogout();
  };

  const profileOpen = profileOptions;
  const prevOpen = useRef(profileOptions);

  useEffect(() => {
    if (prevOpen.current === true && profileOptions === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = profileOptions;
  }, [profileOptions]);

  const showDrawer = true;

  useEffect(() => {
    if (!userHandler.isLogged()) {
      return;
    }
  }, []);

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={open ? { zIndex: 1100 } : { zIndex: 1300 }}
          className={classNames(open ? classes.appBarShift : classes.appBar)}
        >
          <Toolbar disableGutters={!open}>
            {showDrawer && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography color="inherit" className={classes.flex}>
              Bienvenido &nbsp;
              {user ? user.name : ""}
            </Typography>
            <div>
              <IconButton
                ref={anchorRef}
                aria-owns={profileOpen ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleProfileOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorRef.current}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={profileOpen}
                onClose={handleProfileClose}
              >
                <Link to="/">
                  <ListItem button>
                    <ListItemText primary="Inicio" />
                  </ListItem>
                </Link>
                <List>
                  <Link to="/">
                    <ListItem button onClick={handleLogout}>
                      <ListItemText primary="Cerrar sesión" />
                    </ListItem>
                  </Link>
                </List>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        {showDrawer && (
          <LeftBar
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
        )}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes>
            <Route path="/" element={<Exchange />} />
            <Route path="/cupones" element={<CouponPage />} />
            <Route path="/clientes" element={<ClientPage />} />
            <Route path="/error/" element={<PageError />} />
            <Route element={<Page404 />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default Layout;
