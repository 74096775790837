/**
 *
 * Este componente esta basado en:
 *
 * https://material-ui.com/demos/tables/
 */

import React, { useState } from "react";
import * as _ from "lodash";

// material
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

import { toolbarStyles, styles } from "./styles";
import classNames from "classnames";

export type SortOptions = "asc" | "desc";

const getLength = (data: any[] | undefined) => {
  if (!data) {
    return 0;
  } else if (Array.isArray(data)) {
    return data.length;
  } else {
    return Object.keys(data).length;
  }
};

interface EnhancedTableHeadProps {
  onRequestSort: (e: React.MouseEvent<HTMLElement>, prop: string) => void;
  order: "asc" | "desc";
  orderBy: string;
  columns: ColumnDefinition[];
  numSelected: number;
  rowCount: number;
  onSelectAllClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    selected: boolean
  ) => void;
  withCheck: boolean;
}

export interface ColumnDefinition {
  path: string;
  numeric?: boolean;
  disablePadding?: boolean;
  label: string;
  draw?: (cd: ColumnDefinition, value: any) => React.ReactNode;
}

/**
 * Este componente renderiza el HEADER
 */
const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const createSortHandler = (property: string) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      props.onRequestSort(event, property);
    };
  };

  const { order, orderBy, numSelected, rowCount, onSelectAllClick } = props;

  return (
    <TableHead>
      <TableRow>
        {props.withCheck && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {props.columns.map((column) => {
          return (
            <TableCell
              key={column.path}
              // numeric={column.numeric}
              padding={column.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === column.path ? order : false}
            >
              <Tooltip
                title={`Ordenar por ${column.label}`}
                placement={column.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.path}
                  direction={order}
                  onClick={createSortHandler(column.path)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  /**
   * Cantidad de elementos seleccionados
   */
  numSelected: number;
  /**
   * Titulo de la tabla
   */
  title: string;

  noSelectionButtons?: React.ReactNode;
}

/**
 * Este componente solo renderiza el toolbar (y el titulo, etc)
 */
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const renderNoSelectionButtons = () => {
    if (props.noSelectionButtons) {
      return props.noSelectionButtons;
    } else {
      return <div />;
    }
  };

  const classes = toolbarStyles();

  const { numSelected, title } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} seleccionados
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          renderNoSelectionButtons()
        )}
      </div>
    </Toolbar>
  );
};

interface EnhancedTableProps {
  data?: any[];
  title: string;
  columnDefinition: ColumnDefinition[];
  defaultOrder?: {
    name?: string;
    direction?: SortOptions;
  };
  withCheck?: boolean;

  headerButtons?: React.ReactNode;

  wrapInPaper?: boolean;
  showHeader?: boolean;
}

interface EnhancedTableState {
  order: SortOptions;
  orderBy: string;
  selected: any[];
  page: number;
  rowsPerPage: number;
}

const EnhancedTable = (
  props: EnhancedTableProps,
  state: EnhancedTableState
) => {
  const [order, setOrder] = useState(
    (props.defaultOrder && props.defaultOrder.direction) || "desc"
  );
  const [orderBy, setOrderBy] = useState(
    (props.defaultOrder && props.defaultOrder.name) || "id"
  );
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const renderCellContent = (cd: ColumnDefinition, data: any) => {
    if (cd.draw) {
      return cd.draw(cd, data);
    }
    return <span>{_.get(data, cd.path)}</span>;
  };

  const getSorting = (
    order: SortOptions,
    orderBy: string
  ): ((a: any, b: any) => number) => {
    return order === "desc"
      ? (a, b) => (_.get(b, orderBy, "") < _.get(a, orderBy, "") ? -1 : 1)
      : (a, b) => (_.get(a, orderBy, "") < _.get(b, orderBy, "") ? -1 : 1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<HTMLElement>,
    property: string
  ) => {
    const orderBy = property;
    let order: SortOptions = "desc";

    if (orderBy === property && order === "desc") {
      order = "asc";
    }

    setOrder(order);
    setOrderBy(orderBy);
  };

  const handleSelectAllClick =
    () => (event: React.ChangeEvent<HTMLInputElement>, checked: true) => {
      if (!props.data) {
        return;
      }
      if (checked) {
        let selectValues = [];
        if (Array.isArray(props.data)) {
          selectValues = props.data.map((n) => n.id);
          // setSelected(selectValues);
        } else {
          selectValues = Object.keys(props.data);
          // setSelected(selectValues);
        }
        console.log("Select values =>> ", selectValues);

        return;
      }
      setSelected([]);
    };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    if (!props.withCheck) {
      return;
    }

    // const selectedIndex = selected.indexOf(id);
    const selectedIndex = -1;

    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const isSelectedId = (id: number) => {
    console.log("Id select", id);
    return true;
    // return selected.indexOf(id) !== -1;
  };

  const renderRows = (
    order: SortOptions,
    orderBy: string,
    data: any[] | undefined,
    rowsPerPage: number,
    page: number,
    columnDefinition: ColumnDefinition[],
    checkbox: boolean
  ) => {
    const length = getLength(data);
    if (!data || !length) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columnDefinition.length}>
              <Typography>No hay datos que mostrar</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let realData = [];
    if (Array.isArray(data)) {
      realData = data;
    } else {
      realData = Object.keys(data).map((key) => {
        return {
          id: key,
          data,
        };
      });
    }

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, length - page * rowsPerPage);
    let sortedData: any[] = realData.sort(getSorting(order, orderBy));

    if (order === "desc") {
      sortedData = sortedData.reverse();
    }

    return (
      <TableBody>
        {sortedData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((n) => {
            const isSelected = isSelectedId(n.id);
            return (
              <TableRow
                hover
                onClick={(event: any) => handleClick(event, n.id)}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={n.id}
                selected={isSelected}
              >
                {checkbox && (
                  <TableCell padding="checkbox">
                    {/* <Checkbox checked={isSelected} /> */}
                  </TableCell>
                )}
                {columnDefinition.map((cd) => {
                  return (
                    <TableCell
                      key={cd.path}
                      scope="row"
                      component="th"
                      style={{ padding: 4 }}
                      padding={cd.disablePadding ? "none" : "checkbox"}
                      // numeric={cd.numeric === undefined ? false : cd.numeric}
                    >
                      {renderCellContent(cd, n)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 49 * emptyRows }}>
            <TableCell colSpan={columnDefinition.length} />
          </TableRow>
        )}
      </TableBody>
    );
  };

  const { data, title, columnDefinition, withCheck, headerButtons } = props;
  let { wrapInPaper, showHeader } = props;
  const classes = styles();

  const dataSize = getLength(data);

  if (wrapInPaper === undefined) {
    wrapInPaper = true;
  }
  if (showHeader === undefined) {
    showHeader = true;
  }

  const fullTable = (
    <div className={classes.wrapperDiv}>
      {showHeader && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          noSelectionButtons={headerButtons}
        />
      )}
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            columns={columnDefinition}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={dataSize}
            withCheck={withCheck || false}
          />
          {renderRows(
            order,
            orderBy,
            data,
            rowsPerPage,
            page,
            columnDefinition,
            withCheck || false
          )}
        </Table>
      </div>
      <TablePagination
        component="div"
        count={dataSize}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Atras",
        }}
        nextIconButtonProps={{
          "aria-label": "Siguiente",
        }}
        labelRowsPerPage={
          <Typography variant="caption">Elementos por página</Typography>
        }
        labelDisplayedRows={(info) => (
          <Typography variant="caption">
            {info.from}-{info.to} de {info.count}
          </Typography>
        )}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );

  if (wrapInPaper) {
    return <Paper className={classes.root}>{fullTable}</Paper>;
  }
  return fullTable;
};

export default EnhancedTable;
