import React, { FC, useState } from "react";
import authService from "src/services/authService";

// material
import { Button, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// images
import logo from "../../assets/images/coupon.png";

// styles
import { useStyles } from "./styles";

interface LoginProps {
  // auth?: Auth;
  // auth: AuthState;
}

interface LoginState {
  name: string;
  password: string;
}

const Login: FC<LoginProps> = (props: LoginProps) => {
  const [loading, setLoading] = useState(false);
  const userInit: LoginState = {
    name: "",
    password: "",
  };

  const [userLogin, setUserLogin] = useState(userInit);
  const classes = useStyles();

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const email = userLogin.name;
    const password = userLogin.password;

    await authService.userLogin(email, password).catch(() => setLoading(false));
    setLoading(false);
  };

  const handleChange = (name: "name" | "password") => (event: any) => {
    setUserLogin({
      ...userLogin,
      [name]: event.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={12} className={classes.loginContainer}>
        <img src={logo} className={classes.logo} />
        <Typography variant="h6" gutterBottom align="center">
          <strong>Atención al cliente</strong>
        </Typography>
        <Typography gutterBottom>Ingresar</Typography>

        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            id="name"
            label="Usuario"
            className={classes.textField}
            value={userLogin.name}
            onChange={handleChange("name")}
            fullWidth
            margin="normal"
          />
          <TextField
            id="password"
            fullWidth
            label="Contraseña"
            type="password"
            className={classes.textField}
            value={userLogin.password}
            onChange={handleChange("password")}
            margin="normal"
          />
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={loading}
            className={classes.loginButton}
            color="primary"
          >
            Iniciar sesión
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
