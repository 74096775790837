import { createSlice } from "@reduxjs/toolkit";
import { IClientState } from "./types";

const initialState: IClientState = {
  clients: [],
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClients(state, action) {
      state.clients = action.payload;
    },
  },
});

export const { setClients } = clientSlice.actions;
export default clientSlice.reducer;
