import React, { useState } from "react";
import ChangeIcon from "@material-ui/icons/Cached";
import AcceptedIcon from "@material-ui/icons/CheckCircleOutline";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { IconButton, Tooltip } from "@material-ui/core";
import AsyncTable, { ColumnDefinition } from "../AsyncTable";
import { format } from "date-fns";
import { CouponClient } from "../../api/model";
import couponClientService from "src/services/couponClientService";
import ModalDialog from "../Dialog";
import userHandler from "src/utils/userHandler";

interface CouponListProps {
  data: any;
  showChildren: (coupon: any) => void;
}

const ChangeCouponList = (props: CouponListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idCurrentCoupon, setIdCurrentCoupon] = useState(0);
  const companyId = userHandler.getUser()?.companyId || 0;

  const openModal = (id: number) => {
    setIdCurrentCoupon(id);
    setIsOpen(true);
  };

  const actionCancel = () => {
    setIsOpen(false);
  };

  const dismissModal = () => {
    setIsOpen(false);
    markedCoupon(idCurrentCoupon);
  };

  const markedCoupon = (id: number) => {
    couponClientService
      .markCoupon(id, companyId)
      .catch((err) => console.log("Error: ", err));
  };

  const columns: ColumnDefinition[] = [
    {
      path: "coupon.code",
      numeric: false,
      disablePadding: false,
      label: "Código del cupón",
    },
    {
      path: "coupon.name",
      numeric: false,
      disablePadding: false,
      label: "Nombre",
    },
    {
      path: "client.document",
      numeric: false,
      disablePadding: false,
      label: "Cédula",
    },
    {
      path: "client.user.name",
      numeric: false,
      disablePadding: false,
      label: "Cliente",
    },
    {
      path: "acceptDate",
      numeric: false,
      disablePadding: false,
      label: "Fecha de aceptación",
      draw: (cd, v) => v.acceptDate && format(v.acceptDate, "dd/MM/yyyy HH:mm"),
    },
    {
      path: "state",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      draw: (cd, v) => {
        return (
          <div style={{ paddingLeft: 20 }}>
            {v.state === "ACCEPTED" ? (
              <Tooltip title="Aceptado" placement="top">
                <AcceptedIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Canjeado" placement="top">
                <CheckIcon />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      path: "",
      numeric: false,
      disablePadding: false,
      label: "Acciones",
      draw: (cd: ColumnDefinition, value: CouponClient) => {
        return (
          <IconButton
            disabled={value.state === "INTERCHANGED"}
            onClick={() => openModal(value.id)}
          >
            <Tooltip title="Canjear cupón" placement="top">
              <ChangeIcon color="primary"></ChangeIcon>
            </Tooltip>
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <AsyncTable
        title={"Canje de Cupones"}
        wrapInPaper={false}
        columnDefinition={columns}
        data={props.data}
      />
      {isOpen && (
        <ModalDialog
          fullScreen={false}
          title="Canje de cupón"
          open={isOpen}
          message="Procesar el canje del cupón"
          extraMessage="Esta acción no se podrá revertir!"
          dismissModal={dismissModal}
          actionCancel={actionCancel}
        />
      )}
    </>
  );
};

export default ChangeCouponList;
