import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Divider, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

interface ModalDialogProps {
  fullScreen: boolean;
  open: boolean;
  message: string;
  extraMessage?: string;
  dismissModal: () => void;
  title: string;
  actionCancel?: () => void;
}

const ModalDialog = (props: ModalDialogProps) => {
  const classes = useStyles();
  const {
    title,
    dismissModal,
    extraMessage,
    message,
    open,
    fullScreen,
    actionCancel,
  } = props;
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      fullScreen={fullScreen}
    >
      <DialogTitle
        style={{ textAlign: "center", marginTop: 15 }}
        id="simple-dialog-title"
      >
        {title}
      </DialogTitle>
      <Divider />
      <div className={classes.message}>
        <Typography variant="subtitle1">{message}</Typography>
        <br />
        <Typography variant="subtitle2">
          <strong>{extraMessage}</strong>
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="medium"
            variant="contained"
            type="button"
            onClick={dismissModal}
            className={classes.loginButton}
            color="primary"
          >
            Aceptar
          </Button>
          &nbsp;
          <Button
            size="medium"
            variant="contained"
            type="button"
            onClick={actionCancel}
            className={classes.loginButton}
            color="primary"
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalDialog;
