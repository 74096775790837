import React from "react";
import theme from "../utils/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Login from "./Login";
import Layout from "../Layout";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import DateFnsUtils from "@date-io/date-fns";
import * as es from "date-fns/locale/es";
import SnackBar from "../components/SnackBarHandler";

interface MainRouterProps {
  isLogged: boolean;
}

const MainRouter = (props: MainRouterProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <SnackBar />
        {props.isLogged ? <Layout /> : <Login />}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default MainRouter;
