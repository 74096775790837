import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import ReduxLogger from "redux-logger";
import authReducer from "./pages/Login/authSlice";
import notificationReducer from "./notifications/notificationSlice";
import exchangeReducer from "./pages/Exchange/exchangeSlice";
import couponReducer from "./pages/Coupon/couponSlice";
import clientReducer from "./pages/Client/clientSlice";

const middleware = (getDefaultMiddleware: any) =>
  // getDefaultMiddleware().concat(ReduxLogger);
  getDefaultMiddleware();

export const store = configureStore({
  middleware,
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    couponClient: exchangeReducer,
    coupon: couponReducer,
    client: clientReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
