import { createSlice } from "@reduxjs/toolkit";
import { INotification, NotificationType } from "./types";

const initialState: INotification = {
  information: { visible: false },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addError(state, action) {
      state.information = {
        data: { type: NotificationType.ERROR, ...action.payload },
        visible: true,
      };
    },
    addSuccess(state, action) {
      state.information = {
        data: { type: NotificationType.SUCCESS, ...action.payload },
        visible: true,
      };
    },
    addInfo(state, action) {
      state.information = {
        data: { type: NotificationType.INFO, ...action.payload },
        visible: true,
      };
    },
    hide(state) {
      state.information = { ...initialState.information };
    },
  },
});

export const { addError, addInfo, addSuccess, hide } =
  notificationSlice.actions;
export default notificationSlice.reducer;
